import backendRequestUtil from "../utils/backendRequest.util";

export async function getDashboards() {
    const { success, body, error } = await backendRequestUtil.get(`/api/v2/looker/dashboards`)
    return success ? { success, payload: body } : { success, error }
}

export async function generateFileData(bodyToSend) {
  const { success, body, error } = await backendRequestUtil.post(`/api/v2/looker/generateFileData`, bodyToSend)
  return success ? { success, payload: body } : { success, error }
}