import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import NeoTitleMain from "../../design/design_components/neo/title/NeoTitleMain.base";
import MenuBar from "../../design/design_components/sisu/menu/BaseNavBar.sisu";
import Icono from "../../design/assets/img/mnv/icons/icon-dashboard.svg";
import { getDashboards } from "../../service/Looker.service";
import NeoMultiSelect from "../../design/design_components/neo/form/NeoMultiSelect.base";
import NeoCalendar from "../../design/design_components/neo/form/NeoCalendar.base";
import * as _ from "underscore";
import * as UserService from "../../service/User.service";
import DashboardSection from "./components/DashboardSection.component";
import NeoSpinner from "design/design_components/neo/overlay/NeoSpinner.base";
import Routes from "../../routers/Routes.router.json";
import StoreHeatmap from "views/stores/StoreHeatmap.view";

function Home() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  // DATA FULL: Colección de todos los datos usado para los filtros
  const [allStores, setAllStores] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  const [allAPs, setAllAPs] = useState([]);

  // Filtro de rango de fechas
  const [showFilterRangeDates, setShowFilterRangeDates] = useState(false);
  const now = new Date();
  const firstDayOfLastMonth = new Date(now.getFullYear(),now.getMonth() - 1,1);
  const lastDayOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
  const [filterRangeDatesSelected, setFilterRangeDatesSelected] = useState({
    startDate: firstDayOfLastMonth,
    endDate: lastDayOfLastMonth
  });

  // Filtros de sucursal, departamento y APs
  const [showFilterAPs, setShowFilterAPs] = useState(false);
  const [filterStoresOptions, setFilterStoresOptions] = useState([]);
  const [filterStoresOptionsSelected, setFilterStoresOptionsSelected] = useState([]);
  const [filterDepartmentsOptions, setFilterDepartmentsOptions] = useState([]);
  const [filterDepartmentsOptionsSelected, setFilterDepartmentsOptionsSelected] = useState([]);
  const [filterAPsOptions, setFilterAPsOptions] = useState([]);
  const [filterAPsOptionsSelected, setFilterAPsOptionsSelected] = useState([]);

  const [dashboards, setDashboards] = useState({});
  const [itemsMenuNavBar, setItemsMenuNavBar] = useState([]);
  const [itemsPaths, setItemsPaths] = useState([]);
  const [activeDashboard, setActiveDashboard] = useState(null);

  useEffect(() => {
    document.body.classList.add("statistic-page");

    const promiseGetDashboards = getDashboards().then(({ success, payload }) => {
      if (success && payload?.dashboards?.length > 0) {
        let dashboards = payload?.dashboards ?? [];
        dashboards = dashboards.filter((dashboard) => {
          return Boolean(dashboard.CATEGORY_NAME)
            && Boolean(dashboard.NAME)
            && Boolean(dashboard.URL)
        });
        dashboards = _.groupBy(dashboards, (dashboard) => dashboard.CATEGORY_NAME);
        setDashboards(dashboards);
      }
    }).catch(() => {
      console.error("Error al obtener la lista de APs");
    });

    const promiseGetAllAPs = UserService.getAllAPs().then(({ success, payload }) => {
      if (success) {
        const stores = [];
        const seenStoreIds = new Set();
        const deparments = [];
        const seenDeparmentIds = new Set();
        const aps = [];
        payload.forEach((ap) => {
          ap.CUSTOM_AP_NAME = `${ap.AP_NAME} (${ap.AP_MAC_ADDRESS})`;
          if (!seenStoreIds.has(ap.STORE_ID)) {
            stores.push({
              STORE_ID: ap.STORE_ID,
              STORE_NAME: ap.STORE_NAME
            });
            seenStoreIds.add(ap.STORE_ID);
          }
          if (!seenDeparmentIds.has(ap.DEPARMENT_ID)) {
            deparments.push({
              STORE_ID: ap.STORE_ID,
              DEPARMENT_ID: ap.DEPARMENT_ID,
              DEPARMENT_NAME: ap.DEPARMENT_NAME
            });
            seenDeparmentIds.add(ap.DEPARMENT_ID);
          }
          aps.push(ap);
        });
        setAllStores(stores);
        setAllDepartments(deparments);
        setAllAPs(aps);

        // Initial: Select all stores
        setFilterStoresOptionsSelected(stores);
      }
    }).catch(() => {
      console.error("Error al obtener la lista de APs");
    });

    Promise.allSettled([promiseGetDashboards, promiseGetAllAPs]).then(() => {
      setLoading(false);
    });

    return () => {
      document.body.classList.remove("statistic-page");
    };
  }, []);

  useEffect(() => {
    if (dashboards && typeof dashboards === 'object') {
      const { itemsMenuNavBar, itemsPaths } = Object.entries(dashboards).reduce((acc, [CATEGORY_NAME, dashboards]) => {
        const { CATEGORY_ICON, CATEGORY_PATH } = dashboards[0] ?? {};
        
        acc.itemsMenuNavBar.push({
          label: CATEGORY_NAME,
          icon: CATEGORY_ICON,
          items: dashboards.map((dashboard) => {
            const { NAME } = dashboard;
            return {
              label: NAME,
              command: () => {
                history.push({
                  pathname: `${Routes.analyticals.root}/${CATEGORY_PATH}/${dashboard.PATH}`
                });
              }
            }
          })
        });

        acc.itemsPaths.push(...dashboards.map((dashboard) => {
          return {
            path: `${Routes.analyticals.root}/${CATEGORY_PATH}/${dashboard.PATH}`,
            Component: DashboardSection,
            dashboard
          }
        }));

        return acc;
      }, { itemsMenuNavBar: [], itemsPaths: [] });

      // Se agrega al menu y a las rutas los mapas de calor
      itemsMenuNavBar.push({
        label: 'Sucursales',
        icon: 'fa fa-store',
        command: () => {
          history.push({
            pathname: `${Routes.analyticals.root}/sucursales/mapas`
          });
        }
      });
      itemsPaths.push({
        path: `${Routes.analyticals.root}/sucursales/mapas`,
        Component: StoreHeatmap,
        dashboard: {
          FILTER_DATE_START: false,
          FILTER_DATE_END: false,
          FILTER_AP_IDS: false
        }
      });

      setItemsMenuNavBar(itemsMenuNavBar);
      setItemsPaths(itemsPaths);
    }
  }, [dashboards])
 
  useEffect(() => {
    if (activeDashboard) {
      const { FILTER_DATE_START, FILTER_DATE_END, FILTER_AP_IDS } = activeDashboard;
      // Habilita/deshabilita el filtro de rango de fechas
      setShowFilterRangeDates(Boolean(FILTER_DATE_START && FILTER_DATE_END));

      // Habilita/deshabilita el filtro de sucursales, departamentos y APs
      setShowFilterAPs(Boolean(FILTER_AP_IDS));
    };
  }, [activeDashboard])

  useEffect(() => {
    setFilterStoresOptions(allStores);
  }, [allStores])

  useEffect(() => {
    // Busca y establece los departamentos que están ligados a las sucursales seleccionadas
    const filterDepartmentsOptions = allDepartments.filter(({ STORE_ID }) => {
      return filterStoresOptionsSelected.find((store) => store.STORE_ID === STORE_ID);
    });
    setFilterDepartmentsOptions(filterDepartmentsOptions);

    // Selecciona todos los departamentos de las sucursales seleccionadas
    setFilterDepartmentsOptionsSelected(filterDepartmentsOptions);
  }, [filterStoresOptionsSelected])
  
  useEffect(() => {
    // Busca y establece los APs que están ligados a los departamentos seleccionados
    const filterAPsOptions = allAPs.filter(({ DEPARMENT_ID }) => {
      return filterDepartmentsOptionsSelected.find((deparment) => deparment.DEPARMENT_ID === DEPARMENT_ID);
    });
    setFilterAPsOptions(filterAPsOptions);

    // Selecciona todos los APs de los departamentos seleccionados
    setFilterAPsOptionsSelected(filterAPsOptions);
  }, [filterDepartmentsOptionsSelected])

  useEffect(() => {
    // De las opciones de departamentos, busca cuales hay que quitar porque ya no están disponibles para seleccionar
    const filterDepartmentsOptionsSelectedUpdated = filterDepartmentsOptionsSelected.filter(({ DEPARMENT_ID }) => {
      return filterDepartmentsOptions.find((deparment) => deparment.DEPARMENT_ID === DEPARMENT_ID);
    });
    setFilterDepartmentsOptionsSelected(filterDepartmentsOptionsSelectedUpdated);
  }, [filterDepartmentsOptions])

  useEffect(() => {
    // De las opciones de APs, busca cuales hay que quitar porque ya no están disponibles para seleccionar
    const setFilterAPsOptionsSelectedUpdate = filterAPsOptionsSelected.filter(({ DEPARMENT_ID }) => {
      return filterAPsOptions.find((ap) => ap.DEPARMENT_ID === DEPARMENT_ID);
    });
    setFilterAPsOptionsSelected(setFilterAPsOptionsSelectedUpdate);
  }, [filterAPsOptions])

  return (
    <div className="statistics-main-container">
      <div className="statistics-menu-container">
        <NeoTitleMain title="Analíticos" icon={Icono} />
      </div>

      <div className="statistics-menu-filters">

        {loading &&
          <NeoSpinner/>
        }

        {showFilterRangeDates &&
          <NeoCalendar
            cols="3"
            label="Periodo"
            dateFormat="dd/mm/yy"
            value={[filterRangeDatesSelected.startDate, filterRangeDatesSelected.endDate]}
            onChange={(event) => {
              const [startDate, endDate] = event.value;
              setFilterRangeDatesSelected({ startDate, endDate });
            }}
            selectionMode="range"
            readOnlyInput
            extra="custom-neocal-class"
          />
        }

        {showFilterAPs && <>
          <NeoMultiSelect
            label="Sucursal"
            value={filterStoresOptionsSelected}
            onChange={(event) => {
              setFilterStoresOptionsSelected(event.value);
            }}
            options={filterStoresOptions}
            showSelectAll={filterStoresOptions.length > 0}
            optionLabel="STORE_NAME"
            display="chip"
          />
          <NeoMultiSelect
            label="Departamento"
            value={filterDepartmentsOptionsSelected}
            onChange={(event) => {
              setFilterDepartmentsOptionsSelected(event.value);
            }}
            cols="3"
            options={filterDepartmentsOptions}
            showSelectAll={filterDepartmentsOptions.length > 0}
            optionLabel="DEPARMENT_NAME"
            display="chip"
            emptyFilterMessage="Selecciona una sucursal"
          />
          <NeoMultiSelect
            label="AP"
            value={filterAPsOptionsSelected}
            onChange={(event) => {
              console.log("change", event.value)
              setFilterAPsOptionsSelected(event.value);
            }}
            cols="3"
            options={Object.values(filterAPsOptions.reduce((acc, ap) => {
              if (!acc[ap.STORE_ID]) {
                acc[ap.STORE_ID] = {
                  STORE_NAME: ap.STORE_NAME,
                  APS: []
                };
              }
              acc[ap.STORE_ID].APS.push(ap);
              return acc;
            }, {}))}
            showSelectAll={filterAPsOptions.length > 0}
            optionGroupLabel="STORE_NAME"
            optionGroupChildren="APS"
            optionLabel="CUSTOM_AP_NAME"
            display="chip"
            emptyFilterMessage="Selecciona un departamento"
          />
        </>}
      </div>

      <MenuBar
        model={itemsMenuNavBar.map((item) => ({
          label: item.label,
          icon: item.icon,
          command: item.command,
          className: item.className,
          items: item.items || undefined,
        }))}
        className="tab-style-menu"
      />

      <div className="looker-container">
        <Switch>
          {itemsPaths.map(({ path, Component, dashboard }, index) => (
            <Route
              key={index}
              path={path}
              render={() => (
                <Component
                  dashboard={dashboard}
                  filterRangeDatesSelected={filterRangeDatesSelected}
                  filterAPsOptionsSelected={filterAPsOptionsSelected}
                  onMount={() => (setActiveDashboard(dashboard))}
                />
              )}
            />
          ))}

          {/* Se agrega una ruta por defecto al terminar de cargar todos los dashboards */}
          {!loading && itemsPaths.length >= 1 &&
            <Route
              path="*"
              component={() => <Redirect to={itemsPaths[0].path}/>}
            />
          }
        </Switch>
      </div>
    </div>
  );
}
export default React.memo(Home);
