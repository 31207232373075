import React, { useEffect, useState } from "react";

const LookerIframe = React.memo((props) => {
  const [url, setURL] = useState(null);

  useEffect(() => {
    if (props.url instanceof URL) {
      if ((url === null) || (url instanceof URL && (url.toString() !== props.url.toString()))) {
        setURL(props.url);
        if (typeof props.onLoading === "function") {
          props.onLoading(true);
        }
      }
    } else {
      setURL(null);
      if (typeof props.onLoading === "function") {
        props.onLoading(false);
      }
    }
  }, [props.url])

  return (<>
    {url instanceof URL &&
      <iframe
        src={url.toString()}
        onLoad={() => {
          if (typeof props.onLoading === "function") {
            props.onLoading(false);
          }
        }}
        allowFullScreen
      />
    }
  </>)
})

export default LookerIframe;