import React, { useEffect, useState, useRef } from "react";
import NeoButtonSection from "../../../design/design_components/neo/layout/NeoButtonSection.base";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import { ProgressSpinner } from "primereact/progressspinner";
import NeoMessages from "../../../design/design_components/neo/messages/NeoMessages.base";
import LookerIframe from "./LookerIframe.component";
import DoNotRefreshPageDialog from "./DoNotRefreshPageDialog.component";
import { auth } from "../../../config/firebase.config";
import { DateTime } from "luxon";
import { stringIsValidJSON, stringIsValidURL } from "../../../utils/commons.util";
import * as LookerService from '../../../service/Looker.service';

const DashboardSection = React.memo(({ onMount, dashboard, filterRangeDatesSelected, filterAPsOptionsSelected }) => {
  const [url, setURL] = useState(null);
  const [dashboardIsLoading, setDashboardIsLoading] = useState(false);
  const [fileDownloadInProgress, setFileDownloadInProgress] = useState(false);
  const [error, setError] = useState(null);
  const errorMessageRef = useRef(null);

  useEffect(() => {
    if (onMount && typeof onMount === "function") {
      onMount();
    }
  }, [])

  useEffect(() => {
    if (dashboard && dashboard.URL && stringIsValidURL(dashboard.URL)) {
      const url = new URL(dashboard.URL);

      let params = url.searchParams.get('params');
      if (!params) {
        params = {};
      } else if (typeof params === "string" && stringIsValidJSON(params)) {
        params = JSON.parse(params);
      }
      
      // Filter: USER_UID
      if (dashboard.FILTER_USER_UID && dashboard.FILTER_USER_UID_PARAM_NAME) {
        if (auth && auth.currentUser && auth.currentUser.uid) {
          params[dashboard.FILTER_USER_UID_PARAM_NAME] = auth.currentUser.uid;
        } else {
          setError({
            severity: 'error',
            detail: 'Error al obtener el UID del usuario'
          });
          return;
        }
      }

      // Filter: DATE_RANGE
      if (
        (dashboard.FILTER_DATE_START && dashboard.FILTER_DATE_START_PARAM_NAME)
          &&
        (dashboard.FILTER_DATE_END && dashboard.FILTER_DATE_END_PARAM_NAME)
      ) {
        if (filterRangeDatesSelected
          && typeof filterRangeDatesSelected === "object"
          && filterRangeDatesSelected.startDate
          && filterRangeDatesSelected.endDate
        ) {
          let { startDate, endDate } = filterRangeDatesSelected;
          startDate = DateTime.fromJSDate(startDate);
          endDate = DateTime.fromJSDate(endDate);
          if (startDate.isValid && endDate.isValid) {
            params[dashboard.FILTER_DATE_START_PARAM_NAME] = startDate.toFormat('yyyy-MM-dd');
            params[dashboard.FILTER_DATE_END_PARAM_NAME] = endDate.toFormat('yyyy-MM-dd');
          } else {
            setError({
              severity: 'error',
              detail: 'El rango de fechas no es válido'
            });
            return;
          }
        } else {
          setError({
            severity: 'warn',
            detail: 'Seleccione un rango de fechas'
          });
          return;
        }
      }

      // Filter: APS
      if (dashboard.FILTER_AP_IDS && dashboard.FILTER_AP_IDS_PARAM_NAME) {
        if (Array.isArray(filterAPsOptionsSelected) && filterAPsOptionsSelected.length > 0) { 
          params[dashboard.FILTER_AP_IDS_PARAM_NAME] = filterAPsOptionsSelected.map(({ AP_ID }) => AP_ID).join(',');
        } else {
          setError({
            severity: 'warn',
            detail: 'Seleccione al menos un AP de la lista de APs'
          });
          return;
        }
      }

      if (Object.values(params).length > 0) {
        url.searchParams.set('params', JSON.stringify(params));
      }

      setError(null);
      setURL(url);
    } else {
      setError({
        severity: 'error',
        detail: `La URL ${dashboard?.URL} no es válida`
      });
    }
  }, [dashboard, filterRangeDatesSelected, filterAPsOptionsSelected])

  useEffect(() => {
    if (error && typeof error === "object") {
      setURL(null);
      if (errorMessageRef && errorMessageRef.current && typeof errorMessageRef.current.replace === "function") {
        errorMessageRef.current.replace({
          sticky: true,
          closable: false,
          ...error
        });

      }
    } else {
      if (errorMessageRef && errorMessageRef.current && typeof errorMessageRef.current.clear === "function") {
        errorMessageRef.current.clear();
      }
    }
  }, [error, errorMessageRef.current])

  useEffect(() => {
    if(url instanceof URL) {
      
    }
  }, [url])

  return <>
    <div className="statistics-title-download">
      <span>
        {dashboard.CATEGORY_ICON && <i className={`${dashboard.CATEGORY_ICON}`} />}
        {dashboard.CATEGORY_NAME} | {dashboard.NAME}
      </span>

      {(dashboard.DASHBOARD_ID && url instanceof URL && !error) &&
        <NeoButtonSection align="right">
          <NeoButtonMain
            label={fileDownloadInProgress ? "Generando reporte..." : "Descargar registros"}
            icon={fileDownloadInProgress ? "pi pi-spin pi-spinner" : "pi pi-download"}
            disabled={fileDownloadInProgress}
            className="button-with-spinner"
            onClick={() => {
              let params = url.searchParams.get('params');
              if (params && stringIsValidJSON(params)) {
                params = params = JSON.parse(params);
              }

              setFileDownloadInProgress(true);
              LookerService.generateFileData({
                dashboard_id: dashboard.DASHBOARD_ID,
                params
              }).then(({ success, payload, error }) => {
                if (success && payload && payload.url) {
                  window.location.replace(payload.url);
                }
              }).finally(() => {
                setFileDownloadInProgress(false);
              });
            }}
          />
        </NeoButtonSection>
      }
      
    </div>

    {dashboardIsLoading &&
      <div className="loader">
        <ProgressSpinner
          style={{ width: "45px", height: "45px", margin: "10px" }}
          className="custom-spinner" 
        />
          Analizando datos...
      </div>
    }

    {error &&
      <NeoMessages ref={errorMessageRef} />
    }

    <DoNotRefreshPageDialog open={fileDownloadInProgress} />

    {(url instanceof URL && !error) &&
      <LookerIframe
        name={dashboard.NAME}
        url={url}
        onLoading={setDashboardIsLoading}
      />
    }
  </>
})

export default DashboardSection;